<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'proposal-update'"
    v-if="getPermission('proposal:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update {{ proposalSubject }}</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Proposal
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="proposalForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid v-bar class="create-proposal-vbar">
          <v-row>
            <v-col md="6">
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="proposal_subject"
                    class="font-weight-700 font-size-16 required"
                    >Proposal Subject</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    id="proposal_subject"
                    v-model.trim="proposalCreate.subject"
                    dense
                    filled
                    label="Subject"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    :rules="[
                      validateRules.required(
                        proposalCreate.subject,
                        'Proposal Subject'
                      ),
                      validateRules.minLength(
                        proposalCreate.subject,
                        'Proposal Subject',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.subject,
                        'Proposal Subject',
                        100
                      ),
                    ]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16 required"
                    >First Name</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    dense
                    color="cyan"
                    filled
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    v-model.trim="proposalCreate.first_name"
                    label="First Name"
                    :rules="[
                      validateRules.required(
                        proposalCreate.first_name,
                        'First Name'
                      ),
                      validateRules.minLength(
                        proposalCreate.first_name,
                        'First Name',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.first_name,
                        'First Name',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16">Last Name</label>
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    dense
                    color="cyan"
                    filled
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    v-model.trim="proposalCreate.last_name"
                    label="Last Name"
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.last_name,
                        'Last Name',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.last_name,
                        'Last Name',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="customer_company"
                    class="font-weight-700 font-size-16"
                    >Customer Company</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    id="customer_company"
                    v-model.trim="proposalCreate.company"
                    dense
                    filled
                    label="Company"
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.company,
                        'Company',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.company,
                        'Company',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="customer_email"
                    class="font-weight-700 font-size-16"
                    >Customer Email</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    id="customer_email"
                    v-model.trim="proposalCreate.email"
                    dense
                    filled
                    label="Email"
                    :rules="[
                      validateRules.validEmail(proposalCreate.email, 'Email'),
                      validateRules.minLength(proposalCreate.email, 'Email', 1),
                      validateRules.maxLength(
                        proposalCreate.email,
                        'Email',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="primary_phone"
                    class="font-weight-700 font-size-16 required"
                    >Customer Phone No.</label
                  >
                </v-flex>
                <v-flex md8>
                  <PhoneTextField
                    id="primary_phone"
                    v-model="proposalCreate.phone_number"
                    :value="proposalCreate.phone_number"
                    required
                    hideDetails
                    :disabled="pageLoading"
                    :loading="pageLoading"
                  >
                  </PhoneTextField>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="customer_website"
                    class="font-weight-700 font-size-16"
                    >Customer Website</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    id="customer_website"
                    v-model.trim="proposalCreate.website"
                    dense
                    filled
                    label="Website"
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.website,
                        'Website',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.website,
                        'Website',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="proposal_date"
                    class="font-weight-700 font-size-16 required"
                    >Proposal Date</label
                  >
                </v-flex>
                <v-flex md8>
                  <DatePicker
                    solo
                    :min-date="proposalMinDate"
                    :pageLoading.sync="pageLoading"
                    :placeholder="'Proposal Date'"
                    v-model="proposalCreate.proposal_date"
                  ></DatePicker>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="open_till"
                    class="font-weight-700 font-size-16 required"
                    >Open Till</label
                  >
                </v-flex>
                <v-flex md8>
                  <DatePicker
                    solo
                    clearable
                    :min-date="proposalMinDate"
                    :pageLoading.sync="pageLoading"
                    :placeholder="'Open Till'"
                    v-model="proposalCreate.open_till"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout class="my-4">
                <v-flex md4>
                  <label
                    for="proposal_number"
                    class="font-weight-700 font-size-16"
                    >Proposal number</label
                  >
                </v-flex>
                <v-flex md8>
                  <div class="d-inline-flex">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        id="proposal_number"
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="proposalCreate.barcode"
                        label="Proposal number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label for="reference" class="font-weight-700 font-size-16"
                    >Reference #</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    id="reference"
                    dense
                    color="cyan"
                    filled
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    v-model.trim="proposalCreate.reference"
                    label="Reference #"
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.reference,
                        'Reference',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.reference,
                        'Reference',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16">Unit No.</label>
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    v-model.trim="proposalCreate.unit_no"
                    dense
                    filled
                    label="Unit No."
                    solo
                    flat
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.unit_no,
                        'Unit No.',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.unit_no,
                        'Unit No.',
                        100
                      ),
                    ]"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16"
                    >Address Line 1</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    v-model.trim="proposalCreate.address_1"
                    dense
                    filled
                    label="Address Line 1"
                    solo
                    flat
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.address_1,
                        'Address Line 1',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.address_1,
                        'Address Line 1',
                        100
                      ),
                    ]"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16"
                    >Address Line 2</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    v-model.trim="proposalCreate.address_2"
                    dense
                    filled
                    label="Address Line 2"
                    solo
                    flat
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.address_2,
                        'Address Line 2',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.address_2,
                        'Address Line 2',
                        100
                      ),
                    ]"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16"
                    >Postal Code</label
                  >
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    v-model.trim="proposalCreate.zip"
                    dense
                    filled
                    label="Postal Code"
                    v-mask="'######'"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16">Country</label>
                </v-flex>
                <v-flex md8>
                  <v-text-field
                    v-model.trim="proposalCreate.country"
                    dense
                    filled
                    label="Country"
                    :rules="[
                      validateRules.minLength(
                        proposalCreate.country,
                        'Country',
                        1
                      ),
                      validateRules.maxLength(
                        proposalCreate.country,
                        'Country',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout class="my-4">
                <v-flex md4>
                  <label class="font-weight-700 font-size-16">Assigned</label>
                </v-flex>
                <v-flex md8>
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    :items="userList"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    v-model.trim="proposalCreate.assigned_user"
                    label="Assigned"
                    solo
                    flat
                    item-color="cyan"
                    item-text="full_name"
                    item-value="id"
                    hide-details
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No User Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col cols="12" v-if="getPermission('line-item:update')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <ProposalLineItem
                          isProposal
                          :details.sync="proposalCreate"
                          :pageLoading.sync="pageLoading"
                          :updateData.sync="updateLineItem"
                        ></ProposalLineItem>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <ProposalNotesAttachment
                :pageLoading.sync="pageLoading"
                :updateData.sync="updateNotesAttachment"
                isProposal
              ></ProposalNotesAttachment>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import ProposalLineItem from "@/view/pages/partials/Line-Item.vue";
import ProposalNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "proposal-update",
  title: "Update Proposal",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      ProposalUpdated: false,
      LineItemCreated: false,
      proposal: 0,
      proposalMinDate: null,
      barcodeSetting: new Object(),
      updateLineItem: new Array(),
      updateLineItemCalculation: new Object(),
      updateNotesAttachment: new Object(),
      lineItem: new Array(),
      lineItemCalculation: new Object(),
      notesAttachment: new Object(),
      userList: new Array(),
      proposalCreate: new Object({
        reference: null,
        assigned_user: null,
        first_name: null,
        last_name: null,
        name: null,
        company: null,
        email: null,
        website: null,
        phone_number: null,
        country: null,
        zip: null,
        unit_no: null,
        address_1: null,
        address_2: null,
        subject: null,
        status: null,
        is_public: null,
        proposal_date: null,
        open_till: null,
        tax_value: null,
        adjustment: null,
        attachments: [],
        admin_remark: null,
        client_remark: null,
        additional_remarks: null,
      }),
    };
  },
  watch: {
    "proposalCreate.first_name"() {
      this.proposalCreate.name =
        this.proposalCreate.first_name + " " + this.proposalCreate.last_name;
    },
    "proposalCreate.last_name"() {
      this.proposalCreate.name =
        this.proposalCreate.first_name + " " + this.proposalCreate.last_name;
    },
  },
  components: {
    DatePicker,
    PhoneTextField,
    ProposalLineItem,
    ProposalNotesAttachment,
    CreateUpdateTemplate,
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/options",
        })
        .then(({ data }) => {
          _this.barcodeSetting = data.barcode_setting;
          _this.proposalCreate.barcode = data.barcode;
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.proposalForm.validate();

      const formErrors = _this.validateForm(_this.$refs.proposalForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service/package then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.proposalCreate.reference || null,
        assigned_user: _this.proposalCreate.assigned_user || null,
        name: _this.proposalCreate.name || null,
        company: _this.proposalCreate.company || null,
        email: _this.proposalCreate.email || null,
        website: _this.proposalCreate.website || null,
        phone_number: _this.proposalCreate.phone_number || null,
        country: _this.proposalCreate.country || null,
        zip: _this.proposalCreate.zip || null,
        unit_no: _this.proposalCreate.unit_no || null,
        address_1: _this.proposalCreate.address_1 || null,
        address_2: _this.proposalCreate.address_2 || null,
        subject: _this.proposalCreate.subject || null,
        status: _this.proposalCreate.status || null,
        is_public: _this.proposalCreate.is_public || null,
        proposal_date: _this.proposalCreate.proposal_date || null,
        tax:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.tax
            : null,
        tax_type:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.taxType
            : null,
        discount_value:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.discountValue
            : null,
        adjustment:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.adjustmentAmount
            : null,
        discount_type:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.discountType
            : null,
        discount_value_type:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.discountValueType
            : null,
        open_till: _this.proposalCreate.open_till || null,
        admin_remark:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.admin_notes
            : null,
        client_remark:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.client_notes
            : null,
        attachments:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.documents
            : [],
      });

      if (!_this.ProposalUpdated) {
        try {
          let { data } = await _this.$store.dispatch(PUT, {
            url: "proposals/" + _this.proposal,
            data: formData,
          });
          _this.proposal = data.id;
          _this.ProposalUpdated = true;
          _this.backForce = true;
        } catch (error) {
          _this.proposal = 0;
          _this.ProposalUpdated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Proposal is not updated. Please try again.")
          );
        }
      }

      if (!_this.LineItemCreated && _this.ProposalUpdated) {
        try {
          await _this.CreateLineItems({
            type: "proposal",
            parent: _this.proposal,
            formData: _this.lineItem,
          });
          _this.LineItemCreated = true;
        } catch (error) {
          _this.LineItemCreated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not updated. Please try again.")
          );
        }
      }

      _this.formLoading = false;

      if (_this.ProposalUpdated && _this.LineItemCreated) {
        _this.$router.push(
          _this.getDefaultRoute("proposal.detail", {
            params: {
              id: _this.proposal,
            },
          })
        );
      }
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          const name = data.name.split(" ");
          _this.proposalCreate = new Object({
            id: data.id ? data.id : null,
            barcode: data.barcode ? data.barcode : null,
            reference: data.reference ? data.reference : null,
            assigned_user: data.assigned_user ? data.assigned_user.id : null,
            name: data.name ? data.name : null,
            first_name: name[0] || null,
            last_name: name[1] || null,
            company: data.company ? data.company : null,
            email: data.email ? data.email : null,
            website: data.website ? data.website : null,
            phone_number: data.phone_number ? data.phone_number : null,
            country: data.country ? data.country : null,
            zip: data.zip ? data.zip : null,
            unit_no: data.unit_no ? data.unit_no : null,
            address_1: data.address_1 ? data.address_1 : null,
            address_2: data.address_2 ? data.address_2 : null,
            subject: data.subject ? data.subject : null,
            status: data.status ? data.status : null,
            is_public: data.is_public ? data.is_public : null,
            proposal_date: data.date ? data.date : null,
            tax: data.tax ? data.tax : null,
            tax_type: data.tax_type ? data.tax_type : null,
            tax_value: data.tax_value ? data.tax_value : null,
            discount_value: data.discount_value ? data.discount_value : null,
            adjustment: data.adjustment ? data.adjustment : null,
            discount_type: data.discount_type ? data.discount_type : null,
            discount_value_type: data.discount_value_type
              ? data.discount_value_type
              : null,
            open_till: data.open_till ? data.open_till : null,
            admin_remark: data.admin_remark ? data.admin_remark : null,
            client_remark: data.client_remark ? data.client_remark : null,
            attachments: [],
          });

          let lineItems = new Array();
          if (data.line_items && data.line_items.length > 0) {
            for (let i = 0; i < data.line_items.length; i++) {
              lineItems.push({
                original_product: data.line_items[i].original_product,
                id: data.line_items[i].id,
                description: data.line_items[i].description,
                quantity: data.line_items[i].quantity,
                tax: data.line_items[i].tax,
                rate: data.line_items[i].rate,
                total: data.line_items[i].total,
              });
            }
          }

          _this.updateLineItem = lineItems;

          _this.updateLineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            tax_value: data.tax_value,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
          });

          _this.updateNotesAttachment = new Object({
            admin_remark: data.admin_remark,
            client_remark: data.client_remark,
            notify_admin: 0,
            notify_customer: 0,
            notify_engineer: 0,
            documents: data.attachments,
          });
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    NoteAttachmentEventBus.$off("update:notes-attachment");*/
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Proposal", route: "proposal" },
      { title: "Update" },
    ]);

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.proposal <= 0) {
      _this.goBack();
    }

    _this.proposalMinDate = moment().format("YYYY-MM-DD");

    _this.getProposal();

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.notesAttachment = argument;
    });
  },
  computed: {
    proposalSubject() {
      return this.proposalCreate.subject;
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
